import { useState } from "react";
import { Button, Flex, HStack, Image, VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { lighten, transparentize } from "color2k";

import { FeaturedBadge } from "./FeaturedBadge";
import { FeatureItemData } from "./FeaturedItemData";
import { ItemLoader } from "components/loaders/ItemLoader";
import { SectionHeader } from "components/section-header";
import { ErrorBoundary } from "components/error-boundary";

import {
  getColor,
  getHoverStyle,
  getBackgroundStyles,
  getContrastingStyles,
} from "utils/theme";
import { getPrice, toKebabCase } from "utils/general";
import { getShowFeaturedCTA, getShowFeaturedTitle } from "utils/customData";
import { calcContainerRadius, calcButtonRadius } from "./utils";
import { useResizeObserver } from "utils/hooks/useResizeObserver";

export const Featured = ({
  loggedIn = false,
  isLoading = false,
  section = {},
  globalTheme = {},
  pricingTiers = {},
  selected = false,
  onItemSelect = () => {},
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const [contentDimensions, setContentDimensions] = useState({});
  const [setNode] = useResizeObserver((contentRect) => {
    setContentDimensions({
      width: contentRect.width,
      height: contentRect.height,
    });
  });

  const { id = "", item = {}, themeOverride = {}, files = [] } = section;
  const { imageUrl = "", pricingTierLevelId } = item || {};

  // Styles used on the purchase button
  const primaryColor = getColor("primary", themeOverride, globalTheme);
  const secondaryColor = getColor("secondary", themeOverride, globalTheme);
  const primaryColorTransparent = transparentize(
    lighten(primaryColor, 0.3),
    0.8,
  );

  const itemPrice = getPrice(pricingTiers[pricingTierLevelId], {
    loggedIn,
  });

  return (
    <Flex
      w={"100%"}
      minHeight={item?.id ? "auto" : "200px"}
      justifyContent={"center"}
      data-scroll-target={`section-${section.id}`}
      id={`section-${toKebabCase(section.title)}`}
      {...getBackgroundStyles(themeOverride, files)}
      outline={`2px solid ${
        selected ? "var(--chakra-colors-brandRed)" : "transparent"
      }`}
      outlineOffset={"-8px"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onItemSelect(item)}
      cursor={"pointer"}
    >
      <ErrorBoundary name={`featured-${id}`} show={Boolean(item?.id)}>
        <VStack w={"100%"} justifyContent={"center"}>
          {getShowFeaturedTitle() && <SectionHeader section={section} mb="0" />}

          <VStack
            w={"100%"}
            justifyContent={"center"}
            padding={{
              base: "24px",
              smd: "32px",
            }}
          >
            <VStack
              width={"100%"}
              maxW={"var(--max-width)"}
              position={"relative"}
              gap="0"
            >
              <FeatureItemData section={section} />

              <HStack
                ref={setNode}
                width={"100%"}
                flex={"1"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                gap={"16px"}
                borderRadius={calcContainerRadius(contentDimensions?.width)}
                position={"relative"}
                overflow={"hidden"}
                {...getHoverStyle({
                  isHovered,
                  themeOverride,
                  globalTheme,
                  colorKey: "secondary",
                })}
                border={`4px solid ${secondaryColor}`}
              >
                <ItemLoader isLoading={isLoading} />

                <Image
                  src={imageUrl}
                  width={"100%"}
                  height={"100%"}
                  objectFit={"cover"}
                  objectPosition={"center"}
                  zIndex={0}
                />

                <FeaturedBadge
                  contentDimensions={contentDimensions}
                  item={item}
                  themeOverride={themeOverride}
                />

                <ErrorBoundary
                  name={`featured-${id}-purchase`}
                  show={getShowFeaturedCTA()}
                >
                  <Button
                    size={"large"}
                    w={"42%"}
                    h={"16%"}
                    maxH={"60px"}
                    position={"absolute"}
                    bottom={"8%"}
                    right={"6%"}
                    border={"2px solid rgba(22, 23, 25, 0.15)"}
                    boxShadow={"0px 2px 3px 2px rgba(90, 90, 90, 0.11)"}
                    padding={{
                      base: "8px 12px",
                    }}
                    textAlign={"center"}
                    borderRadius={calcButtonRadius(contentDimensions?.width)}
                    _hover={{
                      filter: "brightness(1.2)",
                    }}
                    _active={{
                      filter: "brightness(0.8)",
                    }}
                    outline={`4px solid ${primaryColorTransparent}`}
                    outlineOffset={"-8px"}
                    {...getContrastingStyles(primaryColor, "largeHeadingColor")}
                  >
                    {itemPrice}
                  </Button>
                </ErrorBoundary>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
      </ErrorBoundary>
    </Flex>
  );
};

Featured.propTypes = {
  loggedIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  section: PropTypes.shape({
    id: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  globalTheme: PropTypes.object,
  pricingTiers: PropTypes.object,
  selected: PropTypes.bool,
  onItemSelect: PropTypes.func,
};
